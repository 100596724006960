import {CheckListIcon, Spinner} from "../Svg";

import {PostQuizResults} from "../api";
import {useState} from "react";

import styles from "./results.module.css";

interface ResultsProps {
  quizType: QuizType;
  treatments: Treatment[];
  types: TypeClassification[];
  answers: Record<string, AnswerContainer>;
  didSubmitTest: any;
}

const Results: React.FC<ResultsProps> = ({
  treatments,
  types,
  answers,
  didSubmitTest,
  quizType,
}) => {
  const [submitted, setSubmitted] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [email, setEmail] = useState("");

  const submitQuiz = async (evt: any) => {
    if (email === "") {
      alert("Vänligen ange din e-postaddress");
      return;
    }
    if (!termsAccepted) {
      alert("Du måste godkänna Womnis tjänstevillkor");
      return;
    }
    setSubmitted(true);
    evt.preventDefault();
    const typeScores: Record<string, number> = {};
    types.forEach((ty) => (typeScores[ty.id] = ty.score));
    const treatmentScores: Record<string, number> = {};
    treatments.forEach((ty) => (treatmentScores[ty.id] = ty.score));
    const request = {
      typeScores,
      treatmentScores,
      email,
      answers,
      quizType,
    };
    await PostQuizResults(request);

    try {
      (window as any)._paq.push(["trackEvent", "Quiz", "Submit", quizType]);
    } catch {}
    didSubmitTest();
  };

  return (
    <>
      <div className={styles.results}>
        {!submitted && (
          <>
            <h2>Få ditt resultat</h2>
            <p>
              {quizType === "menopause" && (
                <>
                  Ta del av behandlingar baserade på dina svar, få guider och
                  uppdateringar för ett bättre mående i klimakteriet.
                </>
              )}
              {quizType !== "menopause" && (
                <>
                  Ta del av behandlingar baserade på dina svar, få guider och
                  uppdateringar för ett bättre mående.
                </>
              )}
            </p>
            <input
              className={styles.emailInput}
              type="email"
              placeholder="Ange din e-postaddress"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div
              className={styles.inputContainer}
              onClick={() => setTermsAccepted(!termsAccepted)}>
              <div className={styles.checkboxContainer}>
                <input
                  type="checkbox"
                  checked={termsAccepted}
                  onChange={() => setTermsAccepted(!termsAccepted)}
                />
                <span className={styles.checkmark}></span>
              </div>
              <div className={styles.labelContainer}>
                Jag godkänner{" "}
                <a
                  href="https://womni.se/mailpolicy"
                  target="_blank"
                  rel="noreferrer">
                  Womnis mailpolicy
                </a>
              </div>
            </div>
            <button
              type="submit"
              onClick={submitQuiz}
              className={styles.submitButton}>
              Skicka resultat
            </button>
          </>
        )}
        {submitted && (
          <>
            <Spinner />
          </>
        )}
      </div>
      {!submitted && (
        <>
          <div className={styles.quizInfo}>
            <div className={styles.quizInfoInner}>
              <div className={styles.quizInfoLeft}>
                <h2>
                  Över 40 000 kvinnor har gjort Womnis test och fått hjälp!
                </h2>
                <ul>
                  <li>
                    <CheckListIcon />
                    Resultat och översikt av ditt mående
                  </li>
                  <li>
                    <CheckListIcon />
                    Anpassande behandlingsguider för dig
                  </li>
                  <li>
                    <CheckListIcon />
                    Tips och råd från specialister
                  </li>
                </ul>
                <div className={styles.imageContainer}>
                  <img src="/images/reviews.webp" />
                </div>
              </div>
            </div>
            <div className={styles.quizInfoRight}>
              <div className={styles.imageContainer}>
                {quizType === "menopause" && (
                  <img src="/images/quizdemo-menopause.webp" />
                )}
                {quizType !== "menopause" && (
                  <img src="/images/quizdemo-generic.webp" />
                )}
              </div>
            </div>
          </div>

          <div className={styles.founderInfo}>
            <div className={styles.founderInfoInner}>
              <div className={styles.founderInfoLeft}>
                <div className={styles.imageContainer}>
                  <img src="/images/naomi.webp" />
                </div>
              </div>
              <div className={styles.founderInfoRight}>
                <h2>En grundares perspektiv</h2>
                <h3>Naomi Flamholc, Grundare och chefsläkare</h3>
                <blockquote>
                  Som läkare inom allmänmedicin och med ett stort intresse av
                  kvinnosjukdomar och hormoner har jag i mitt jobb noterat att
                  många kvinnor går med besvär relaterade till klimakteriet i
                  lång tid utan att veta var och hur de ska söka hjälp. Jag blir
                  illa till mods när jag inser att kvinnan inte har information
                  nog om vad klimakteriet egentligen är, hur länge det varar och
                  hur det kan yttra sig. Utan denna information är det svårt för
                  kvinnor att veta vilken hjälp man kan kräva att få inom
                  sjukvården. Det råder också enormt stora nationella olikheter
                  i hur och var hjälp kan fås, något som går emot svenska
                  sjukvårdens motto om ”Lika vård för alla”.
                </blockquote>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Results;
