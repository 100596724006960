import BoolQuestion from "./BoolQuestion";
import RangeQuestion from "./RangeQuestion";
import SingleChoiceQuestion from "./SingleChoiceQuestion";
import MultiChoiceQuestion from "./MultiChoiceQuestion";
import BmiQuestion from "./BmiQuestion";
import DeadEndQuestion from "./DeadEndQuestion";

interface QuestionSelectProps {
  question: Question;
  answer: AnswerContainer | null;
  onDidAnswerQuestion: (value: any, additional: any) => void;
}

const QuestionSelect: React.FC<QuestionSelectProps> = ({
  question,
  answer,
  ...rest
}) => {
  switch (question.kind) {
    case "bool":
      return <BoolQuestion {...{question, answer, ...rest}} />;
    case "range":
      return <RangeQuestion {...{question, answer, ...rest}} />;
    case "singlechoice":
      return <SingleChoiceQuestion {...{question, answer, ...rest}} />;
    case "multichoice":
      return <MultiChoiceQuestion {...{question, answer, ...rest}} />;
    case "bmi":
      return <BmiQuestion {...{question, ...rest}} />;
    case "deadEnd":
      return <DeadEndQuestion {...{question, ...rest}} />;
    default:
      throw new Error(`QuestionKind: ${question.kind} is not supported`);
  }
};

export default QuestionSelect;
