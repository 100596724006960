import clsx from "clsx";
import styles from "./rangeQuestion.module.css";

interface RangeQuestionProps {
  question: Question;
  answer: AnswerContainer | null;
  onDidAnswerQuestion: (value: number, options: {showNext: boolean}) => void;
}

const RangeQuestion: React.FC<RangeQuestionProps> = ({
  question,
  onDidAnswerQuestion,
  answer,
}) => {
  const options = (() => {
    const options = [];
    const {min, max} = question;
    for (var i = min; i <= max; i++) {
      options[i] = i;
    }
    return options;
  })();
  return (
    <div className={styles.rangeQuestion}>
      <div>
        {options.map((num) => (
          <button
            key={num}
            onClick={() =>
              onDidAnswerQuestion(num, {
                showNext: true,
              })
            }
            className={clsx(styles.rangeButton, {
              [styles.selected]: answer?.answer === num,
            })}>
            {num}
          </button>
        ))}
      </div>
      <div className={styles.rangeDirectionIndicator}>
        <span>Lite</span>
        <span>Mycket</span>
      </div>
    </div>
  );
};

export default RangeQuestion;
