import styles from "./buttons.module.css";

interface SubmitButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {}

export const SubmitButton: React.FC<SubmitButtonProps> = ({
  children,
  ...props
}) => {
  return (
    <button type="submit" {...props} className={styles.submitButton}>
      {children}
    </button>
  );
};
interface LinkButtonProps
  extends React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  > {}
export const LinkButton: React.FC<LinkButtonProps> = ({children, ...props}) => {
  return (
    <a {...props} className={styles.linkButton}>
      {children}
    </a>
  );
};
