import styles from "./boolQuestion.module.css";
import clsx from "clsx";

interface BoolQuestionProps {
  question: Question;
  answer: AnswerContainer | null;
  onDidAnswerQuestion: (value: any, options: {showNext: boolean}) => void;
}

const BoolQuestion: React.FC<BoolQuestionProps> = ({
  question,
  onDidAnswerQuestion,
  answer,
}) => {
  return (
    <div className={styles.boolQuestion}>
      {question.options.map((option) => (
        <button
          key={option.value}
          onClick={() =>
            onDidAnswerQuestion(option.value, {
              showNext: true,
            })
          }
          className={clsx({
            [styles.selected]: answer?.answer === option.value,
          })}>
          {option.title}
        </button>
      ))}
    </div>
  );
};

export default BoolQuestion;
