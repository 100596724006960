import clsx from "clsx";
import styles from "./singleChoiceQuestion.module.css";
import React from "react";
import {chunks} from "../util";

interface SingleChoiceQuestionProps {
  question: Question;
  answer: AnswerContainer | null;
  onDidAnswerQuestion: (value: string, options: {showNext: boolean}) => void;
}

const SingleChoiceQuestion: React.FC<SingleChoiceQuestionProps> = ({
  question,
  onDidAnswerQuestion,
  answer,
}) => {
  const rows = chunks(question.options, 2);
  return (
    <div className={styles.singleChoiceQuestion}>
      {rows.map((row, rowindex) => (
        <div key={rowindex} className={styles.row}>
          {row.map((option, index) => (
            <div
              key={index}
              onClick={() =>
                onDidAnswerQuestion(option.value, {showNext: true})
              }
              className={clsx(styles.col, {
                [styles.selected]: option.value === answer?.answer,
              })}>
              {option.title}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default SingleChoiceQuestion;
