import {LinkButton} from "./Buttons";
import styles from "./deadEndQuestion.module.css";

interface DeadEndQuestionProps {
  question: Question;
  onDidAnswerQuestion: (value: any, options: {showNext: boolean}) => void;
}

const DeadEndQuestion: React.FC<DeadEndQuestionProps> = ({question}) => {
  return (
    <div className={styles.boolQuestion}>
      {question.linkUrl && question.linkText && (
        <LinkButton href={question.linkUrl}>{question.linkText}</LinkButton>
      )}
    </div>
  );
};

export default DeadEndQuestion;
