import clsx from "clsx";
import styles from "./multiChoiceQuestion.module.css";
import {chunks} from "../util";

interface MultiChoiceQuestionProps {
  question: Question;
  answer: AnswerContainer | null;
  onDidAnswerQuestion: (value: any, options: {showNext: boolean}) => void;
}

const MultiChoiceQuestion: React.FC<MultiChoiceQuestionProps> = ({
  question,
  onDidAnswerQuestion,
  answer,
}) => {
  const isSelected = (value: string) => {
    if (answer?.answer && Array.isArray(answer?.answer)) {
      return answer?.answer.indexOf(value) !== -1;
    }
    return false;
  };

  const toggleOption = (value: string) => {
    const tmp = (answer?.answer as string[]) || [];
    const index = tmp.indexOf(value);
    if (index === -1) {
      tmp.push(value);
    } else {
      tmp.splice(index, 1);
    }

    onDidAnswerQuestion(tmp, {showNext: false});
  };

  const rows = chunks(question.options, 2);

  return (
    <div className={styles.multiChoiceQuestion}>
      {rows.map((row, rowIndex) => (
        <div className={styles.row} key={rowIndex}>
          {row.map((option, index) => (
            <div
              onClick={() => toggleOption(option.value)}
              key={index}
              className={clsx(styles.col, {
                [styles.selected]: isSelected(option.value),
              })}>
              <div className={styles.inputContainer}>
                <div className={styles.checkboxContainer}>
                  <input
                    id={index + "_" + rowIndex}
                    type="checkbox"
                    onChange={() => toggleOption(option.value)}
                    checked={isSelected(option.value)}
                  />
                  <span className={styles.checkmark}></span>
                </div>
                <div className={styles.labelContainer}>
                  <label htmlFor={index + "_" + rowIndex}>{option.title}</label>
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default MultiChoiceQuestion;
