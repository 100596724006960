import {SubmitButton} from "./Buttons";
import styles from "./welcome.module.css";

interface WelcomeProps {
  quizType: QuizType;
  onContinue: () => void;
}

const Welcome: React.FC<WelcomeProps> = (props) => {
  if (props.quizType === "pms") {
    return <WelcomePMS {...props} />;
  }
  return <WelcomeMenopause {...props} />;
};

const WelcomePMS: React.FC<WelcomeProps> = ({onContinue}) => {
  return (
    <div className={styles.welcome}>
      <h1>Womnis Självskattningstest PMS</h1>
      <p>
        Inför ett vårdmöte hos Womni ber vi patienten fylla i Womnis
        självskattningstest för PMS som är utformat av läkare. Även fast du inte
        är patient kan du närsomhelst göra testet för att få en översikt av dina
        symtom och guide till behandlingar direkt till din mail.
      </p>
      <p>
        Detta test ersätter inte en professionell medicinsk diagnos eller
        behandling. Om du känner behov av professionell rådgivning kan du boka
        tid hos en av våra{" "}
        <a href="https://womni.se/pms">erfarna läkare eller barnmorskor</a> med
        stor kunskap inom kvinnlig hälsa.
      </p>
      <SubmitButton
        type="submit"
        onClick={onContinue}
        className={styles.submitButton}>
        Börja testet
      </SubmitButton>
    </div>
  );
};

const WelcomeMenopause: React.FC<WelcomeProps> = ({onContinue}) => {
  return (
    <div className={styles.welcome}>
      <h1>Womnis Självskattningstest</h1>
      <p>
        Inför ett vårdmöte hos Womni ber vi patienten fylla i Womnis
        klimakterietest som är utformat av läkare. Även fast du inte är patient
        kan du närsomhelst göra testet för att få en översikt av dina symtom och
        guide till behandlingar direkt till din mail.
      </p>
      <p>
        Detta test ersätter inte en professionell medicinsk diagnos eller
        behandling. Om du känner behov av professionell rådgivning kan du boka
        tid hos en av våra{" "}
        <a href="https://womni.se/boka-tid">erfarna läkare eller barnmorskor</a>{" "}
        med stor kunskap inom klimakterievård.
      </p>
      <SubmitButton
        type="submit"
        onClick={onContinue}
        className={styles.submitButton}>
        Börja testet
      </SubmitButton>
    </div>
  );
};

export default Welcome;
