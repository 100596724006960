import {useState} from "react";
import styles from "./bmiQuestion.module.css";

const calculateBmi = (cm: number, kg: number) =>
  Math.round(kg / Math.pow(cm / 100, 2));

interface BmiQuestionProps {
  onDidAnswerQuestion: (
    value: number,
    options: {showNext: boolean; weight?: number; height?: number}
  ) => void;
}

const BmiQuestion: React.FC<BmiQuestionProps> = ({onDidAnswerQuestion}) => {
  const [height, setHeight] = useState<number | undefined>(undefined);
  const [weight, setWeight] = useState<number | undefined>(undefined);
  const bmi = height && weight ? calculateBmi(height, weight) : null;
  return (
    <div className={styles.bmiQuestion}>
      <div className={styles.bmiQuestionFormSection}>
        <label htmlFor="height">Din längd (cm):</label>
        <input
          type="number"
          id="height"
          value={height}
          onChange={(e) => setHeight(Number.parseInt(e.currentTarget.value))}
        />
      </div>
      <div className={styles.bmiQuestionFormSection}>
        <label htmlFor="weight">Din vikt (kg):</label>
        <input
          type="number"
          id="height"
          value={weight}
          onChange={(e) => setWeight(Number.parseInt(e.currentTarget.value))}
        />
      </div>
      {height && weight && bmi && (
        <div>
          <div className={styles.bmiIndicator}>Din BMI är {bmi}</div>
          <button
            onClick={() =>
              onDidAnswerQuestion(bmi, {
                height,
                weight,
                showNext: false,
              })
            }>
            Fortsätt
          </button>
        </div>
      )}
    </div>
  );
};

export default BmiQuestion;
