import {useCallback, useEffect, useState} from "react";
import {Logo} from "../Svg";

import QuestionSelect from "../components/QuestionSelect";

import styles from "./quiz.module.css";
import Results from "../components/Results";
import Welcome from "../components/Welcome";
import {GetQuiz} from "../api";

const onUnload = () => {
  return "Är du säker på att du vill avsluta testet?";
};

const itemMatchesAnswer = (
  sgt: AnswerMatcher,
  answer: AnswerValue
): boolean => {
  if (Array.isArray(answer))
    return answer.some((a) => itemMatchesAnswer(sgt, a));
  if (sgt.ifValue === answer || sgt.ifValue === (answer === "true"))
    return true;
  if (sgt.ifValueInRange) {
    return (
      Number(answer) >= sgt.ifValueInRange.min &&
      Number(answer) <= sgt.ifValueInRange.max
    );
  }
  return false;
};

const checkWarning = (question: Question, answer: AnswerValue) => {
  if (!question.warningMessages) return;
  for (const warning of question.warningMessages) {
    if (itemMatchesAnswer(warning, answer)) {
      alert(warning.title);
    }
  }
};

const getQuizTypeFromPath = (): QuizType => {
  const path = window.location.pathname;
  switch (path) {
    case "/pms":
      return "pms";
    case "/menopause":
      return "menopause";
    default:
      return "menopause";
  }
};

const QuizPage = () => {
  const [quizType] = useState<QuizType>(getQuizTypeFromPath());
  const [done, setDone] = useState<boolean>(false);
  const [started, setStarted] = useState<boolean>(false);
  const [answers, setAnswers] = useState<Record<string, AnswerContainer>>({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [numQuestions, setNumQuestions] = useState<number | null>(null);
  const [treatments, setTreatments] = useState<Treatment[] | null>(null);
  const [types, setTypes] = useState<TypeClassification[] | null>(null);
  const [questions, setQuestions] = useState<Question[] | null>(null);
  const currentQuestion = questions ? questions[currentQuestionIndex] : null;

  const loadQuestions = useCallback(async () => {
    const quiz = await GetQuiz(quizType);
    setNumQuestions(
      quiz.questions.filter((q: Question) => q.id.indexOf(".") === -1).length
    );
    setQuestions(quiz.questions);
    // set default empty answer for all multichoice
    // questions.
    quiz.questions.forEach((question: Question) => {
      if (question.kind === "multichoice") {
        updateAnswer(question, [], null);
      }
    });
    setTreatments(
      quiz.treatments.map((t) => {
        return {...t, score: 0};
      })
    );
    setTypes(
      quiz.types.map((t) => {
        return {...t, title: "Typ " + t.id, score: 0};
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadQuestions();
  }, [loadQuestions]);

  const updateTreatments = (question: Question, answer: AnswerValue) => {
    if (!question.suggestedTreatments) return;
    if (!treatments) return;
    for (const sgt of question.suggestedTreatments) {
      if (itemMatchesAnswer(sgt, answer)) {
        for (const t of treatments) {
          if (t.id === sgt.treatmentId) {
            t.score += sgt.score;
          }
        }
      }
    }
    setTreatments(treatments);
  };

  const updateTypes = (question: Question, answer: AnswerValue) => {
    if (!question.typeClassifications) return;
    if (!types) return;
    for (const tc of question.typeClassifications) {
      if (itemMatchesAnswer(tc, answer)) {
        for (const t of types) {
          if (t.id === tc.typeId) {
            t.score += tc.score;
          }
        }
      }
    }
  };

  const isVisible = (question: Question) => {
    if (question.visibility.length === 0) return true;
    for (const visibilty of question.visibility) {
      const answer = answers[visibilty.questionId];
      if (!answer) {
        continue;
      }
      if (visibilty.values.some((v) => v === "HIDDEN")) return false;
      if (visibilty.values.some((v) => answer.answer === v)) {
        return true;
      }
    }

    return false;
  };

  const onNextQuestionButtonPressed = () => {
    if (!currentQuestion || !questions) {
      return;
    }
    const {answer} = answers[currentQuestion.id];

    updateTreatments(currentQuestion, answer);
    updateTypes(currentQuestion, answer);
    checkWarning(currentQuestion, answer);

    for (let i = currentQuestionIndex + 1; i < questions.length; i++) {
      const question = questions[i];
      if (isVisible(question) === false) continue;
      setCurrentQuestionIndex(i);
      return;
    }

    setDone(true);
    try {
      (window as any)._paq.push(["trackEvent", "Quiz", "Finished", quizType]);
    } catch {}
  };

  const onPrevButtonPressed = () => {
    if (!questions) {
      return;
    }
    setCurrentQuestionIndex(currentQuestionIndex - 1);

    for (let i = currentQuestionIndex - 1; i >= 0; i--) {
      const question = questions[i];
      if (isVisible(question) === false) continue;
      setCurrentQuestionIndex(i);
      break;
    }
  };

  const updateAnswer = (
    question: Question,
    answer: AnswerValue,
    additional: any
  ) => {
    answers[question.id] = {
      question: question.title,
      answer,
      depth: (question.id.match(/i\./g) || []).length,
      additional,
    };
    setAnswers(Object.assign({}, answers));
  };

  const onDidAnswerQuestion = (
    answer: AnswerValue,
    options: {showNext: Boolean; additional: any}
  ) => {
    if (!currentQuestion) {
      return;
    }
    const {showNext, additional} = options;
    updateAnswer(currentQuestion, answer, additional);
    if (showNext) {
      onNextQuestionButtonPressed();
    }
  };

  const didSubmitTest = () => {
    window.removeEventListener("beforeunload", onUnload);
    setTimeout(() => {
      if (quizType === "pms") {
        window.location.href = "https://womni.se/quiz-pms-landing";
      } else {
        window.location.href = "https://womni.se/quiz-landing";
      }
    }, 500);
  };

  const startTest = () => {
    setStarted(true);
    window.addEventListener("beforeunload", onUnload);
    try {
      (window as any)._paq.push(["trackEvent", "Quiz", "Start", quizType]);
    } catch {}
  };

  const rootIndex = (id: string) => {
    return Number(id.split(".")[0]);
  };

  const currentAnswer = currentQuestion ? answers[currentQuestion.id] : null;

  return (
    <div className={styles.quiz}>
      <div className={styles.quizHeader}>
        <div className={styles.logoContainer}>
          <a href="https://womni.se">
            <Logo />
          </a>
        </div>
        {questions && currentQuestion && numQuestions && (
          <div className={styles.progressContainer}>
            <div
              className={styles.progress}
              style={{
                width: `${
                  (rootIndex(currentQuestion.id) / numQuestions) * 100
                }%`,
              }}></div>
          </div>
        )}
      </div>
      {started && treatments && types && done && (
        <Results
          quizType={quizType}
          treatments={treatments}
          types={types}
          answers={answers}
          didSubmitTest={didSubmitTest}
        />
      )}
      {!done && (
        <div className={styles.quizBody}>
          {!started && <Welcome quizType={quizType} onContinue={startTest} />}

          {started && !done && currentQuestion && (
            <div className={styles.question}>
              <h1 className={styles.questionTitle}>{currentQuestion.title}</h1>
              {currentQuestion.subtitle && (
                <div
                  className={styles.questionSubTitle}
                  dangerouslySetInnerHTML={{
                    __html: currentQuestion.subtitle,
                  }}></div>
              )}
              <div className={styles.questionBody}>
                <QuestionSelect
                  answer={currentAnswer}
                  question={currentQuestion}
                  onDidAnswerQuestion={onDidAnswerQuestion}
                />
              </div>
              <div className={styles.questionFooter}>
                <div className={styles.quizFooterInner}>
                  {currentQuestionIndex > 0 && (
                    <button
                      className={styles.backButton}
                      onClick={onPrevButtonPressed}>
                      ‹
                    </button>
                  )}
                  <button
                    disabled={currentAnswer == null}
                    className={styles.continueButton}
                    onClick={onNextQuestionButtonPressed}>
                    Nästa fråga ›
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default QuizPage;
