export const GetQuiz = async (quizType: QuizType): Promise<Quiz> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/quiz/${quizType}`
  );
  if (!response.ok) {
    throw response.statusText;
  }
  return response.json();
};

export const PostQuizResults = async (
  createQuizResultsRequest: CreateQuizResultsRequest
) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/quiz-results`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(createQuizResultsRequest),
    }
  );

  if (!response.ok) {
    throw response.statusText;
  }

  return await response.json();
};
