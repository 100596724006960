export const Logo = () => {
  return (
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 465.12 112.94">
      <g>
        <g>
          <path
            d="M27.35,45.47l-4.22-8.31c-3.01-5.89-10.28-8.19-16.25-5.14l-0.24,0.12C0.68,35.2-1.71,42.44,1.3,48.33l12.02,23.51
                l20.79,41.1l13.72-27.12L27.38,45.47L27.35,45.47z"
          />
          <path
            d="M84.58,78.97L67.62,45.59l-0.07-0.13l0,0l-5.42-10.67c-3.01-5.89-10.23-8.22-16.11-5.21l-0.51,0.26
                c-5.89,3.01-8.22,10.23-5.21,16.11l11.3,22.09l22.71,44.9l13.72-27.12l-3.48-6.85L84.58,78.97z"
          />
          <path
            d="M106.72,43.91c-0.8-5.23-5.08-9.5-10.3-10.3c-8.39-1.28-15.52,5.84-14.23,14.24c0.8,5.23,5.08,9.5,10.31,10.3
                C100.89,59.43,108.01,52.3,106.72,43.91z"
          />
        </g>
        <path
          d="M137.2,73.2c0,19.86,6.28,36.44,12.77,39.73c-19.02,0-40.44-15.67-40.44-39.73c0-24.07,21.42-39.74,40.44-39.74
            C142.4,36.8,137.2,53.33,137.2,73.2z M194.2,73.2c0,24.06-21.42,39.73-40.44,39.73c6.49-3.3,12.77-19.87,12.77-39.73
            c0-19.87-5.2-36.39-12.77-39.74C172.78,33.46,194.2,49.13,194.2,73.2z"
        />
        <path
          d="M204.92,52.74l24.49-19.28v79.47h-24.49V52.74z M275.61,52.13l0,60.8h-24.49V46.86l-17.22,0.15l12.27-9.66
            c5.5-4.33,13.23-5.27,19.97-1.71C272.14,38.82,275.61,45.34,275.61,52.13z M321.81,52.21l0,60.72h-24.49V46.55l-16.66,0.02
            l11.71-9.22c5.53-4.36,13.33-5.29,20.07-1.64C318.39,38.94,321.81,45.45,321.81,52.21z"
        />
        <path
          d="M406.62,52.16l0,60.77h-24.49V46.87l-17.22,0.15l12.27-9.66c5.49-4.32,13.18-5.26,19.91-1.74
            C403.14,38.78,406.62,45.35,406.62,52.16z M448.34,99.73h16.78l-11.52,9.07c-2.18,1.72-4.67,3.07-7.38,3.67
            c-11.86,2.65-22.37-6.31-22.37-17.72V47.18h24.49V99.73z M449.23,28.92c0.06-0.49,0.15-0.98,0.15-1.49c0-0.89-0.11-1.75-0.29-2.59
            c-0.65-6.21-3.69-12.15-8.57-16.59c-4.89-4.45-13.93-9.45-29.12-8c-15.11,1.43-30.2,10.17-44.85,26c-2.15,2.33-4.72,4.89-7.61,7.63
            c-2.24,2.08-4.5,4.12-6.67,6.01l-16.33,12.86v60.19h24.49V37.57l0.38-0.36c3.28-3.07,6.13-5.91,8.47-8.44
            c14.01-15.13,28.3-23.48,42.47-24.82c13.86-1.3,21.95,3.11,26.28,7.06c1.79,1.63,3.27,3.49,4.45,5.51
            c-1.6-0.77-3.37-1.24-5.26-1.24c-6.72,0-12.16,5.45-12.16,12.16s5.45,12.16,12.16,12.16c6.2,0,11.26-4.66,12.01-10.66l0,0V28.92z"
        />
      </g>
    </svg>
  );
};

export const Spinner = () => {
  return (
    <svg className="spinner" viewBox="0 0 50 50">
      <circle
        className="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke-width="5"></circle>
    </svg>
  );
};

export const CheckListIcon = () => {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.2373 0.902344C12.2706 0.902344 9.37049 1.78208 6.90376 3.4303C4.43702 5.07852 2.51443 7.4212 1.37912 10.1621C0.243804 12.903 -0.053246 15.919 0.525532 18.8287C1.10431 21.7384 2.53292 24.4112 4.63071 26.5089C6.7285 28.6067 9.40124 30.0353 12.311 30.6141C15.2207 31.1929 18.2367 30.8958 20.9776 29.7605C23.7184 28.6252 26.0611 26.7026 27.7093 24.2359C29.3576 21.7692 30.2373 18.8691 30.2373 15.9023C30.2373 11.9241 28.657 8.10879 25.8439 5.29574C23.0309 2.4827 19.2156 0.902344 15.2373 0.902344ZM15.2373 27.9991C12.8448 27.9991 10.506 27.2896 8.5167 25.9604C6.5274 24.6312 4.97692 22.742 4.06135 20.5316C3.14577 18.3212 2.90622 15.8889 3.37297 13.5424C3.83973 11.1958 4.99184 9.04039 6.6836 7.34863C8.37536 5.65687 10.5308 4.50476 12.8773 4.038C15.2239 3.57125 17.6561 3.8108 19.8665 4.72638C22.0769 5.64196 23.9662 7.19243 25.2954 9.18173C26.6246 11.171 27.3341 13.5098 27.3341 15.9023C27.3341 19.1106 26.0596 22.1875 23.791 24.4561C21.5224 26.7246 18.4456 27.9991 15.2373 27.9991Z"
        fill="#9051A3"
      />
      <path
        d="M13.7859 17.7215L10.4569 14.3925L8.40527 16.4441L12.7601 20.7989C13.0323 21.0708 13.4012 21.2234 13.7859 21.2234C14.1706 21.2234 14.5395 21.0708 14.8117 20.7989L22.5537 13.057L20.502 11.0054L13.7859 17.7215Z"
        fill="#9051A3"
      />
    </svg>
  );
};
